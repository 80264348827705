<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     :title="`Chỉ tiêu: ${client.name}`" titleIcon="fa fa-dot-circle">

                <template #cell(year)="{item}">
                    <span v-if="item.year != -1" >Năm {{item.year}}</span>
                    <span v-else >Toàn dự án</span>
                </template>

                <template #cell(time)="{item}">
                    <div>{{item.updated_time * 1000 | datetimeFormat}}</div>
                </template>

                <template #cell(items)="{item}">
                    <div v-if='params.items[item.id]' >
                        <div v-for="(val, code) in params.items[item.id]" :key="code" >
                            <i v-if="indicators[code]">- <b v-if="indicators[code].code">{{indicators[code].code}}</b> {{indicators[code].name}} <span v-if="indicators[code].required" class="text-danger" >(*)</span></i>
                            <i v-else>- {{code}}</i>: 
                            <b class='text-danger ml-2'>{{val.toFixed(2).replace(/\.0+$/,'')}}</b> 
                        </div>
                    </div>
                    <div v-else >
                        <span class="small text-gray-999">Chưa thiết lập</span> 
                    </div>
                </template>

                <template slot="action">
                    <g-button class="ml-2" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>

                <template #cell(action)="{item}">
                    <div class="btn-action-3" >
                        <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1" icon="pencil" @click='update(item.id)' />
                        <g-button :loading="delLoading" size="sm" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.id)' />
                    </div>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class="mb-3">
                <b>Ghi chú:</b> <span class="text-danger" >(*)</span> chỉ số trong báo cáo kỹ thuật (Bản trình NACCET), <b>(PIRS CODE)</b> chỉ số của USAID
            </div>
            <form-select :disabled='formModel.id' label="Năm cần đạt chỉ tiêu" :model="formModel" attribute="year" :options="yearOptions" :errors="formErrors"/>

            <table class='table table-striped table-hover'>
                <tr>
                    <th>Chỉ số</th>
                    <th class='text-center'>Chỉ tiêu</th>
                    <th class='text-center'>Tần suất báo cáo</th>
                </tr>
                <tbody>
                    <tr v-for="(indicator, code) in indicators" :key="code" >
                        <td><b v-if="indicator.code">{{indicator.code}}</b> {{indicator.name}} <span v-if="indicator.required" class="text-danger" >(*)</span></td>
                        <td><form-input type="number" :model="formModel.items" :attribute="code" :errors="formErrors" class='mb-0'/></td>
                <td class='text-center'>{{getReportLabel(indicator.report)}}</td>
                </tr>
                </tbody>
            </table>

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import moment from 'moment';
    import role from '@/mixins/Role';

    export default {
        props: ["id"],
        components: {},
        mixins: [list, crud, role],
        data: function () {
            return {
                autoLoad: false,
                reloadBus: "reloadKpi",
                service: '/kpi',
                fields: [
                    {key: 'year', label: "Thời gian"},
                    {key: 'items', label: "Chỉ tiêu"},
                    {key: 'time', label: "Cập nhật lúc", class: 'text-center'},
                    {key: 'action', label: "Hành động", class: 'text-center'},
                ],
                client: this.id ? null : this.$store.state.client,
                defaultFilterFormModel: {
                    client_id: this.id ? this.id : this.$store.state.client.id,
                    parent_id: this.$store.state.client.id,
                    quarter: null,
                    year: null
                },
                defaultFormModel: {
                    client_id: this.id ? this.id : this.$store.state.client.id,
                    parent_id: this.$store.state.client.id,
                    quarter: moment().quarter(),
                    year: moment().format("YYYY"),
                    items: {}
                },
                yearOptions: [
                    {value: null, text: "Chọn năm"},
                ],
                indicators: {}
            };
        },
        watch: {
            id: {
                handler() {
                    this.setClient();
                }, deep: true
            }
        },
        methods: {
            create: function () {
                this.defaultFormModel.items = {};
                for (let code in this.$params.indicator) {
                    this.defaultFormModel.items[code] = "";
                }

                this.formModel = {};
                this.formModel = Object.assign({}, this.defaultFormModel);
                this.formTitle = `Thiết lập chỉ tiêu cho đơn vị ${this.client.name}`;
                this.formErrors = {};
                this.showForm = true;
            },
            setClient() {
                let id = this.id ? this.id : this.$store.state.client.id;
                this.defaultFilterFormModel.client_id = id;
                this.defaultFormModel.client_id = id;
                this.filterFormModel.client_id = id;
                this.formModel.client_id = id;

                this.$service.get(`/client/get?id=${id}`).then(response => {
                    this.client = response.data;
                    this.indicators = {};
                    for (let code in this.$params.indicator) {
                        let indicator = this.$params.indicator[code];
                        if (indicator.indicator !== undefined && indicator.indicator === false) {
                            continue;
                        }
                        if (this.client.indicators && this.client.indicators.length && !this.client.indicators.includes(code)) {
                            continue;
                        }
                        this.indicators[code] = indicator;
                    }
                    this.load();
                });
            },
            getReportLabel(reports) {
                let labels = [];
                if (reports && reports.length) {
                    for (let option of reports) {
                        if (option === 'year') {
                            labels.push("Năm");
                        } else if (option === 'quarter') {
                            labels.push("Quý")
                        } else if (option === 'turn') {
                            labels.push("Lượt")
                        }
                    }
                }
                return labels.join(", ");
            }
        },
        mounted: async function () {
            this.yearOptions = [
                {value: null, text: "Chọn năm"},
                {value: -1, text: "Toàn dự án"},
            ];
            let currentYear = Number(moment().format("YYYY"));
            for (let i = 2019; i <= currentYear + 1; i++) {
                this.yearOptions.push({value: i, text: `Năm ${i}`});
            }
            if (this.isSub()) {
                let response = await this.$service.get('/kpi/get-prime', {
                    params: {
                        quarter: moment().quarter(),
                        year: moment().format("YYYY"),
                    }
                });
                if (response.data.clients && response.data.clients.length) {
                    this.$router.push({path: `/manager/kpi/view/${response.data.clients[0].id}`});
                    return false;
                }
            }
            if (this.isTW() || this.isUsaid()) {
                let response = await this.$service.get('/kpi/get-tw-usaid', {
                    params: {
                        quarter: moment().quarter(),
                        year: moment().format("YYYY"),
                    }
                });
                if (response.data.clients && response.data.clients.length) {
                    this.$router.push({path: `/manager/kpi/view/${response.data.clients[0].id}`});
                    return false;
                }
            }
            this.setClient();
        }
    }
</script>